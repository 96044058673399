import React, { useState } from 'react';
import { Form, Button, InputGroup, ProgressBar } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import zxcvbn from 'zxcvbn';
import { useNavigate } from 'react-router-dom';
import './ResetPasswordForm.css';

const ResetPasswordForm = ({ token }) => {
    const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    // Handle password input changes and update strength
    const handleNewPasswordChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);
    };

    // Validate the form inputs
    const validateForm = () => {
        const newErrors = [];
        if (!newPassword) {
            newErrors.push({ message: "A <b>New Password</b> is required.", boldFields: ["New Password"] });
        }
        if (newPassword && (newPassword.length < 8 || !/[A-Za-z]/.test(newPassword) || !/[0-9]/.test(newPassword))) {
            newErrors.push({ message: "Password must be at least 8 characters long and contain a mix of letters and numbers.", boldFields: [] });
        }
        return newErrors;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setShowErrorModal(true);
            return;
        }

        try {
            const response = await fetch('https://api.foveaai.com/evaluate/web/user/reset_password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                    new_password: newPassword,
                }),
            });

            if (response.ok) {
                setSuccessMessage('Your password has been successfully reset. You can now log in with your new password.');
                setNewPassword('');
            } else {
                const errorData = await response.json();
                setErrors([{ message: errorData.message || "Failed to reset password. Please try again.", boldFields: [] }]);
                setShowErrorModal(true);
            }
        } catch (error) {
            console.error("Error resetting password:", error);
            setErrors([{ message: "An unexpected error occurred. Please try again later.", boldFields: [] }]);
            setShowErrorModal(true);
        }
    };

    // Determine password strength label and color
    const getPasswordStrength = (password) => {
        const result = zxcvbn(password);
        return result.score;
    };

    const isPasswordAcceptable = (password) => {
        return password.length >= 8 && /[A-Za-z]/.test(password) && /[0-9]/.test(password);
    };

    const getPasswordStrengthLabel = (password) => {
        if (isPasswordAcceptable(password)) {
            return { label: 'Accepted', color: 'success' };
        } else {
            return { label: 'Weak', color: 'danger' };
        }
    };

    const passwordStrength = getPasswordStrength(newPassword);
    const { label: passwordStrengthLabel, color: passwordStrengthColor } = getPasswordStrengthLabel(newPassword);

    return (
        <div className="reset-password-form-container">
            {successMessage ? (
                <div className="success-message">
                    <p>{successMessage}</p>
                    <Button onClick={() => navigate('/login')} className="btn btn-success">Go to Login</Button>
                </div>
            ) : (
                <Form className="reset-password-form" onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                        <Form.Label htmlFor="newPassword">New Password</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="newPassword"
                                name="newPassword"
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                required
                                placeholder="Enter new password"
                            />
                            <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className="form-group">
                        <Form.Label></Form.Label>
                        <div className="password-strength-container">
                            <ProgressBar
                                now={(passwordStrength + 1) * 20}
                                label={passwordStrengthLabel}
                                variant={passwordStrengthColor}
                                className="password-strength-bar"
                            />
                        </div>
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary">Reset Password</Button>
                </Form>
            )}
            {showErrorModal && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <h3>Oops! Please check the following:</h3>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>
                                    {error.message.split(" ").map((word, i) => (
                                        error.boldFields.includes(word.replace(/[^a-zA-Z]/g, "")) ? <b key={i}>{word} </b> : `${word} `
                                    ))}
                                </li>
                            ))}
                        </ul>
                        <Button onClick={() => setShowErrorModal(false)} className="btn btn-danger">Close</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResetPasswordForm;