import React from 'react';
import { useNavigate } from 'react-router-dom';
import './RestrictedPage.css';

const RestrictedPage = () => {
    const navigate = useNavigate();

    const navigateToDashboard = () => {
        navigate('/dashboard');
    };

    return (
        <div className="RestrictedPage">
            <h1>Uh-Oh</h1>
            <p>This page is restricted to users with permissions different than yours or your organization does not have the required subscription. Please contact an administrator if you believe you should have access to this page.</p>
            <p>You can return to your FoveaAI Dashboard by clicking below.</p>
            <button onClick={navigateToDashboard}>Go to Dashboard</button>
        </div>
    );
};

export default RestrictedPage;
