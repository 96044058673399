import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import '../RegisterForm/RegisterForm.css'; // Reuse existing styles
import { getUserRoles } from '../../utils/SecurityUtils'; // Import getUserRoles

const CompanyProfileTab = () => {
    const [companyProfile, setCompanyProfile] = useState({
        name: '',
        address_line_1: '',
        address_line_2: '',
        locality: '',
        administrative_area: '',
        country: '',
        postal_code: ''
    });
    const [errors, setErrors] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [cookies] = useCookies(['AuthorizationToken']);

    useEffect(() => {
        const checkUserRole = async () => {
            const roles = await getUserRoles();
            console.log('User roles:', roles); // Log the roles to debug
            const hasAdminOrPrimaryRole = roles.some(role => role.name === 'admin' || role.name === 'primary');
            setIsAdmin(hasAdminOrPrimaryRole);
        };

        const fetchCompanyProfile = async () => {
            try {
                const response = await axios.get('https://api.foveaai.com/evaluate/web/company/profile', {
                    headers: {
                        'Authorization': cookies.AuthorizationToken
                    }
                });
                setCompanyProfile(response.data);
            } catch (error) {
                console.error("Error fetching company profile:", error);
                setErrors([{ message: error.response?.data?.message || "Failed to fetch company profile.", boldFields: [] }]);
                setShowErrorModal(true);
            }
        };

        checkUserRole();
        fetchCompanyProfile();
    }, [cookies.AuthorizationToken]);

    const validateForm = () => {
        const newErrors = [];

        if (companyProfile.locality && !/^[A-Za-z\s\-']+$/.test(companyProfile.locality)) {
            newErrors.push({ message: "Locality must contain only alphabetic characters, spaces, hyphens, and apostrophes.", boldFields: [] });
        }

        if (companyProfile.administrative_area && !/^[A-Za-z\s\-']+$/.test(companyProfile.administrative_area)) {
            newErrors.push({ message: "Administrative area must contain only alphabetic characters, spaces, hyphens, and apostrophes.", boldFields: [] });
        }

        if (companyProfile.country && !/^[A-Za-z\s\-']+$/.test(companyProfile.country)) {
            newErrors.push({ message: "Country must contain only alphabetic characters, spaces, hyphens, and apostrophes.", boldFields: [] });
        }

        if (companyProfile.postal_code && !/^[A-Za-z0-9]+$/.test(companyProfile.postal_code)) {
            newErrors.push({ message: "Postal code must be alphanumeric.", boldFields: [] });
        }

        return newErrors;
    };

    const handleChange = (e) => {
        setCompanyProfile({
            ...companyProfile,
            [e.target.name]: e.target.value || '' // Ensure value is not undefined
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setShowErrorModal(true);
            return;
        }

        try {
            const response = await axios.put('https://api.foveaai.com/evaluate/web/company/profile', companyProfile, {
                headers: {
                    'Authorization': cookies.AuthorizationToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                setSuccessMessage(response.data.message || 'Company profile updated successfully.');
            } else {
                setErrors([{ message: response.data.message || "Failed to update company profile.", boldFields: [] }]);
                setShowErrorModal(true);
            }
        } catch (error) {
            setErrors([{ message: error.response?.data?.message || "An unexpected error occurred.", boldFields: [] }]);
            setShowErrorModal(true);
        }
    };

    if (!isAdmin) {
        return (
            <div className="register-form-container">
                <p>You do not have the necessary permissions to view or edit the company profile.</p>
            </div>
        );
    }

    return (
        <div className="register-form-container">
            {successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}
            <Form className="register-form" onSubmit={handleSubmit}>
                <Form.Group className="form-group">
                    <Form.Label htmlFor="name">Company Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="name"
                        name="name"
                        value={companyProfile.name || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter company name"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="address_line_1">Address Line 1</Form.Label>
                    <Form.Control
                        type="text"
                        id="address_line_1"
                        name="address_line_1"
                        value={companyProfile.address_line_1 || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter address line 1"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="address_line_2">Address Line 2</Form.Label>
                    <Form.Control
                        type="text"
                        id="address_line_2"
                        name="address_line_2"
                        value={companyProfile.address_line_2 || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter address line 2"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="locality">City</Form.Label>
                    <Form.Control
                        type="text"
                        id="locality"
                        name="locality"
                        value={companyProfile.locality || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter locality"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="administrative_area">State/Province</Form.Label>
                    <Form.Control
                        type="text"
                        id="administrative_area"
                        name="administrative_area"
                        value={companyProfile.administrative_area || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter administrative area"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="postal_code">Postal Code</Form.Label>
                    <Form.Control
                        type="text"
                        id="postal_code"
                        name="postal_code"
                        value={companyProfile.postal_code || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter postal code"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="country">Country</Form.Label>
                    <Form.Control
                        type="text"
                        id="country"
                        name="country"
                        value={companyProfile.country || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter country"
                    />
                </Form.Group>

                <Button type="submit" className="btn btn-primary">Update Company Profile</Button>
            </Form>

            {showErrorModal && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <h3>Oops! Please check the following:</h3>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>
                                    {error.message.split(" ").map((word, i) => (
                                        error.boldFields.includes(word.replace(/[^a-zA-Z]/g, "")) ? <b key={i}>{word} </b> : `${word} `
                                    ))}
                                </li>
                            ))}
                        </ul>
                        <Button onClick={() => setShowErrorModal(false)} className="btn btn-danger">Close</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanyProfileTab;