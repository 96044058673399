import "./EulaForm.css";

import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { marked } from "marked";

const EULAComponent = ({ show, onHide }) => {
    const [agreements, setAgreements] = useState({
        eula: "",
        privacy: "",
        beta: "",
        refund: "",
    });
    const [checkedStates, setCheckedStates] = useState({
        eula: false,
        privacy: false,
        beta: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [modal, setModal] = useState({ open: false, content: "" });
    const navigate = useNavigate();
    const eulaID = 4;
    const [isBetaRequired, setIsBetaRequired] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        const fetchAgreements = async () => {
            try {
                const response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/eula/${eulaID}`,
                    {
                        headers: {
                            Authorization: cookies.AuthorizationToken,
                        },
                    }
                );
                const data = JSON.parse(response.data);
                setAgreements({
                    eula: marked(data.eula_content),
                    privacy: marked(data.privacy_content),
                    beta: marked(data.beta_content),
                    refund: marked(data.refund_content),
                });

                // Check if beta content is not empty and set isBetaRequired accordingly
                setIsBetaRequired(data.beta_content.trim() !== "");
            } catch (error) {
                console.error("Failed to fetch EULA", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAgreements();
    }, [cookies.AuthorizationToken]);

    const toggleModal = (contentKey, title) =>
        setModal({
            open: !modal.open,
            content: agreements[contentKey] || "",
            title: title,
        });

    const handleChange = (e) => {
        setCheckedStates({
            ...checkedStates,
            [e.target.name]: e.target.checked,
        });
    };

    const allChecked =
        checkedStates.eula && checkedStates.privacy && (!isBetaRequired || checkedStates.beta);

    const handleAccept = async () => {
        try {
            await axios.post(
                "https://api.foveaai.com/evaluate/web/eula/acceptances",
                {
                    eula_id: eulaID,
                    method_of_acceptance: "web_click_agree",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: cookies.AuthorizationToken,
                    },
                }
            );

            // Recorded EULA acceptance; redirect to dashboard
            console.log("Agreements accepted");

            // Redirect to dashboard page
            navigate("/dashboard");
        } catch (error) {
            console.error("Failed to record agreements acceptance", error);

            // Redirect to login page
            navigate("/login");
        }
    };

    const handleDecline = () => {
        // Optionally redirect or handle the decline action
        console.log("EULA declined");

        // Redirect to login page
        navigate("/login");
    };

    return (
        <div className="eula-container">
            {isLoading && <LoadingOverlay />}
            <div className="eula-content">
                <h1 className="eula-title">End User Agreements</h1>
                <div className="eula-directions">
                    You must agree to the End User License Agreement, Privacy
                    Agreement, and Beta Agreement prior to using FoveaAI's services.
                    Please read the following agreements carefully before
                    accepting. <b>All items must be agreed to and accepted
                    prior to clicking the "Accept" button.</b>
                </div>
                <div className="eula-checkboxes">
                    <div>
                        <input
                            type="checkbox"
                            id="eula-checkbox"
                            name="eula"
                            checked={checkedStates.eula}
                            onChange={handleChange}
                            className="checkbox-input"
                        />
                        <label htmlFor="eula-checkbox" className="checkbox-label">
                            I have read and agree to the{" "}
                            <a
                                href="#!"
                                onClick={() =>
                                    toggleModal(
                                        "eula",
                                        "End User License Agreement"
                                    )
                                }
                            >
                                End User License Agreement
                            </a>
                            .
                        </label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="privacy-checkbox"
                            name="privacy"
                            checked={checkedStates.privacy}
                            onChange={handleChange}
                            className="checkbox-input"
                        />
                        <label htmlFor="privacy-checkbox" className="checkbox-label">
                            I have read and agree to the{" "}
                            <a
                                href="#!"
                                onClick={() =>
                                    toggleModal("privacy", "Privacy Policy Agreement")
                                }
                            >
                                Privacy Policy Agreement
                            </a>
                            .
                        </label>
                    </div>
                    {isBetaRequired && (
                        <div>
                            <input
                                type="checkbox"
                                id="beta-checkbox"
                                name="beta"
                                checked={checkedStates.beta}
                                onChange={handleChange}
                                className="checkbox-input"
                            />
                            <label htmlFor="beta-checkbox" className="checkbox-label">
                                I have read and agree to the{" "}
                                <a
                                    href="#!"
                                    onClick={() =>
                                        toggleModal("beta", "Beta Tester Agreement")
                                    }
                                >
                                    Beta Tester Agreement
                                </a>
                                .
                            </label>
                        </div>
                    )}
                </div>
                <div className="eula-actions">
                    <Button color="secondary" onClick={handleDecline}>
                        Decline
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={!allChecked}
                        onClick={handleAccept}
                        style={{ marginTop: 0 }}
                    >
                        Accept
                    </Button>
                </div>
                <Modal isOpen={modal.open} toggle={() => toggleModal("", "")}>
                    <ModalHeader toggle={() => toggleModal("", "")}>
                        {modal.title}
                    </ModalHeader>
                    <ModalBody
                        dangerouslySetInnerHTML={{ __html: modal.content }}
                    />
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => toggleModal("", "")}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
};

export default EULAComponent;
