import React from 'react';
import './RequestPasswordResetPage.css';
import RequestPasswordResetForm from '../../components/RequestPasswordResetForm/RequestPasswordResetForm';
import FoveaAILogo from "../../assets/images/foveaai_logo_dark_text.svg";

const RequestPasswordResetPage = () => {
    return (
        <div className="RequestPasswordResetPage">
            <div className="LogoContainer">
                <img className="FoveaAILogo" src={FoveaAILogo} alt="FoveaAI" />
            </div>
            <div className="Slogan">
                Identify top candidates in minutes.
            </div>
            <div className="ResetArea">
                <h1>Reset Your Password</h1>
                <p className="ResetDescription">Enter your email address below to receive a password reset link.</p>
            </div>
            <RequestPasswordResetForm />
        </div>
    );
}

export default RequestPasswordResetPage;