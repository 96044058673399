import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import LogoUpload from '../LogoUpload/LogoUpload';
import HeaderUpload from '../HeaderUpload/HeaderUpload';
import FooterUpload from '../FooterUpload/FooterUpload';
import '../RegisterForm/RegisterForm.css'; // Reuse existing styles

const LogoManagementTab = () => {
    return (
        <Tabs defaultActiveKey="logo" id="logo-management-tabs" className="mb-3">
            <Tab eventKey="logo" title="Logo">
                <LogoUpload />
            </Tab>
            <Tab eventKey="header" title="Header">
                <HeaderUpload />
            </Tab>
            <Tab eventKey="footer" title="Footer">
                <FooterUpload />
            </Tab>
        </Tabs>
    );
};

export default LogoManagementTab;