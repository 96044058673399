import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import '../RegisterForm/RegisterForm.css'; // Reuse existing styles
import './UserProfileTab.css'; // Import the updated CSS

const UserProfileTab = () => {
    const [profile, setProfile] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: ''
    });
    const [errors, setErrors] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [resetPasswordMessage, setResetPasswordMessage] = useState('');
    const [showResetButton, setShowResetButton] = useState(true); // New state to control button visibility
    const [cookies] = useCookies(['AuthorizationToken']);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get('https://api.foveaai.com/evaluate/web/user/profile', {
                    headers: {
                        'Authorization': cookies.AuthorizationToken
                    }
                });
                setProfile(response.data);
            } catch (error) {
                console.error("Error fetching user profile:", error);
                setErrors([{ message: error.response?.data?.message || "Failed to fetch profile.", boldFields: [] }]);
                setShowErrorModal(true);
            }
        };

        fetchProfile();
    }, [cookies.AuthorizationToken]);

    const validateForm = () => {
        const newErrors = [];

        if (profile.first_name && !/^[A-Za-z]+$/.test(profile.first_name)) {
            newErrors.push({ message: "First name must contain only alphabetic characters.", boldFields: [] });
        }

        if (profile.last_name && !/^[A-Za-z]+$/.test(profile.last_name)) {
            newErrors.push({ message: "Last name must contain only alphabetic characters.", boldFields: [] });
        }

        if (profile.phone && !/^\+?(\d{1,4})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}(\s*(ext|x|extension)\s*\d{1,5})?$/.test(profile.phone)) {
            newErrors.push({ message: "Phone number must be in a valid international format.", boldFields: [] });
        }

        return newErrors;
    };

    const handleChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value || '' // Ensure value is not undefined
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setShowErrorModal(true);
            return;
        }

        try {
            const response = await axios.put('https://api.foveaai.com/evaluate/web/user/profile', profile, {
                headers: {
                    'Authorization': cookies.AuthorizationToken,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                setSuccessMessage(response.data.message || 'Profile updated successfully.');
                setProfile({
                    ...profile,
                    email: profile.email // Assuming email is not updatable
                });
            } else {
                setErrors([{ message: response.data.message || "Failed to update profile.", boldFields: [] }]);
                setShowErrorModal(true);
            }
        } catch (error) {
            setErrors([{ message: error.response?.data?.message || "An unexpected error occurred.", boldFields: [] }]);
            setShowErrorModal(true);
        }
    };

    const handleResetPassword = async () => {
        try {
            const response = await axios.post('https://api.foveaai.com/evaluate/web/user/request_password_reset', { email: profile.email });
            if (response.status === 200) {
                setResetPasswordMessage('A password reset link has been sent to your email address. Please check your inbox.');
                setShowResetButton(false); // Hide the reset button after success
            } else {
                setErrors([{ message: response.data.message || 'Failed to request password reset.', boldFields: [] }]);
                setShowErrorModal(true);
            }
        } catch (error) {
            setErrors([{ message: error.response?.data?.message || 'An unexpected error occurred.', boldFields: [] }]);
            setShowErrorModal(true);
        }
    };

    return (
        <div className="register-form-container">
            {successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}
            <Form className="register-form" onSubmit={handleSubmit}>
                <Form.Group className="form-group">
                    <Form.Label htmlFor="first_name">First Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="first_name"
                        name="first_name"
                        value={profile.first_name || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter your first name"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="last_name">Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="last_name"
                        name="last_name"
                        value={profile.last_name || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter your last name"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="phone">Phone Number</Form.Label>
                    <Form.Control
                        type="tel"
                        id="phone"
                        name="phone"
                        value={profile.phone || ''} // Ensure value is not undefined
                        onChange={handleChange}
                        placeholder="Enter your phone number"
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label htmlFor="email">Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        value={profile.email || ''} // Ensure value is not undefined
                        readOnly
                        placeholder="Your email address"
                        style={{ backgroundColor: '#f0f0f0' }} // Light gray background
                    />
                </Form.Group>

                <Form.Group className="form-group">
                    <Form.Label></Form.Label> {/* Empty label for alignment */}
                    {showResetButton ? (
                        <Button variant="link" onClick={handleResetPassword} className="reset-password-button">
                            Click to reset password
                        </Button>
                    ) : (
                        resetPasswordMessage && (
                            <div className="reset-password-message">
                                {resetPasswordMessage}
                            </div>
                        )
                    )}
                </Form.Group>

                <Button type="submit" className="btn btn-primary">Update Profile</Button>
            </Form>

            {showErrorModal && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <h3>Oops! Please check the following:</h3>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>
                                    {error.message.split(" ").map((word, i) => (
                                        error.boldFields.includes(word.replace(/[^a-zA-Z]/g, "")) ? <b key={i}>{word} </b> : `${word} `
                                    ))}
                                </li>
                            ))}
                        </ul>
                        <Button onClick={() => setShowErrorModal(false)} className="btn btn-danger">Close</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserProfileTab;