import React from 'react';
import VerifyComponent from '../../components/VerifyComponent/VerifyComponent';
import FoveaAILogo from "../../assets/images/foveaai_logo_dark_text.svg";
import './VerifyPage.css';

const VerifyPage = () => {
    return (
        <div className="VerifyPage">
            <div className="LogoContainer">
                <img className="FoveaAILogo" src={FoveaAILogo} alt="FoveaAI" />
            </div>
            <div className="Slogan">
                Identify top candidates in minutes.
            </div>
            <div className="VerifyArea">
                <h1>Email Verification</h1>
                <p className="VerifyDescription">Please verify your email address to complete your new FoveaAI account creation.</p>
            </div>
            <VerifyComponent />
        </div>
    );
};

export default VerifyPage;