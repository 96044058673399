import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import '../RegisterForm/RegisterForm.css'; // Reuse existing styles
import './BillingTab.css'; // Import the updated CSS
import { getUserRoles } from '../../utils/SecurityUtils'; // Import getUserRoles
import SubscriptionForm from '../SubscriptionForm/SubscriptionForm';
const BillingTab = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [cookies] = useCookies(['AuthorizationToken']);
    const [error, setError] = useState(null);

    useEffect(() => {
        const checkUserRole = async () => {
            const roles = await getUserRoles();
            const hasAuthorizedRole = roles.some(role => role.name === 'admin' || role.name === 'primary');
            setIsAdmin(hasAuthorizedRole);
        };

        checkUserRole();
    }, [cookies.AuthorizationToken]);

    const handleManageBilling = async () => {
        try {
            const response = await axios.post('https://api.foveaai.com/evaluate/web/user/billing_portal', {
                baseClientUrl: window.location.origin // Send the base client URL
            }, {
                headers: {
                    'Authorization': cookies.AuthorizationToken,
                    'Content-Type': 'application/json'
                }
            });

            const { url } = response.data;
            window.location.href = url; // Redirect to Stripe Billing Portal
        } catch (err) {
            console.error("Error creating billing session:", err);
            setError(err.response?.data?.message || "There was an error opening the billing portal.");
        }
    };

    if (!isAdmin) {
        return (
            <div className="register-form-container">
                <p>You do not have the necessary permissions to view the Billing information.</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="register-form-container">
                <div className="error-modal">
                    <div className="error-modal-content">
                        <h3>Oops! An error occurred:</h3>
                        <p>{error}</p>
                        <Button onClick={() => setError(null)} className="btn btn-danger">Close</Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="register-form-container">
            <div className="billing-info">
                <h2>Subscription & Billing</h2>
                <p>Change your FoveaAI plan.</p>
                <SubscriptionForm />
                <p className="manage-billing-text" onClick={handleManageBilling}>
                    Manage payment information, view receipts, or cancel your subscription.
                </p>
                <div className="billing-policy-container">
                <p className="billing-policy-text">
                    Upgrades are credited for subscription payments previously made within the current 
                    billing period. Upgrades take effect immediately upon successful payment of any 
                    outstanding charges. Downgrades retain full access to the current plan until the 
                    next billing period. Your subscription will then be changed to the new plan and 
                    billed accordingly. Refunds are not available or provided.
                </p>
                </div>
            </div>
        </div>
    );
};

export default BillingTab;