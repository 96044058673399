import "./AddCandidatesDialog.css";

import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const MAX_RESUME_UPLOADS = 50;

const ResumeUploadDialog = ({
    job_id,
    closeModal = null,
    refreshData = null,
}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [resumeText, setResumeText] = useState("");
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);

    const handleFileChange = (event) => {
        let files = Array.from(event.target.files);

        if (event.target.files.length > MAX_RESUME_UPLOADS) {
            files = Array.from(event.target.files).slice(0, MAX_RESUME_UPLOADS);
            alert(
                `A maximum of ${MAX_RESUME_UPLOADS} resumes can be uploaded at once. Your selection has been reduced to the first ${MAX_RESUME_UPLOADS} resumes. Click Upload to accept this selection or click "Choose Files" again and limit to ${MAX_RESUME_UPLOADS} resumes.`
            );
        }

        console.log("Selected files:", files.length);
        setSelectedFiles(files);
    };

    const handleUploadSubmit = async (event) => {
        event.preventDefault();
        setIsUploading(true);

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("resume", file);
        });

        try {
            const response = await axios.post(
                `https://api.foveaai.com/evaluate/web/candidates/upload/${job_id}`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: cookies.AuthorizationToken,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        setUploadProgress(percentCompleted);
                    },
                }
            );
            if (response.status !== 202) {
                throw new Error(response.data.message);
            }

            // Successfully uploaded so clean up and close modal
            setResumeText("");
            if (refreshData) refreshData();
            if (closeModal) closeModal();
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "An unexpected error occurred.");
            setShowError(true);
        } finally {
            // Reset upload state since we were either successful or an error occurred
            setIsUploading(false);
            setUploadProgress(0);
        }
    };

    const handleTextSubmit = async (event) => {
        event.preventDefault();
        setIsUploading(true);
    
        try {
            const response = await axios.post(
                `https://api.foveaai.com/evaluate/web/candidates/job/${job_id}`,
                {
                    resume: resumeText,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: cookies.AuthorizationToken,
                    },
                }
            );
            if (response.status !== 202) {
                throw new Error(response.data.message);
            }
            console.log("Submitted resume text:", response.data);

            // Successfully uploaded so clean up and close modal
            setResumeText("");
            if (refreshData) refreshData();
            if (closeModal) closeModal();
        } catch (error) {
            // Error uploading so display error message
            setErrorMessage(error.response?.data?.message || "An unexpected error occurred.");
            setShowError(true);
        } finally {
            // Reset upload state since we were either successful or an error occurred
            setIsUploading(false);
        }
    };

    return (
        <Tabs>
            <TabList>
                <Tab>Upload Resumes</Tab>
                <Tab>Paste Resume</Tab>
            </TabList>
            <TabPanel>
                <Form onSubmit={handleUploadSubmit}>
                    <FormGroup>
                        <div className="upload-instructions">
                            <Label for="resumeUpload">
                                <p>
                                    Upload resumes available in PDF, Microsoft
                                    Word, or plain text files. Candidate
                                    evaluations typically take 2 minutes per
                                    resume. The completed evaluation will appear
                                    in the list after a browser refresh. Maximum
                                    50 resumes per upload.
                                </p>
                            </Label>
                        </div>
                        <Input
                            type="file"
                            name="file"
                            id="resumeUpload"
                            multiple
                            onChange={handleFileChange}
                        />
                    </FormGroup>
                    <div
                        className="progress"
                        style={{
                            visibility: isUploading ? "visible" : "hidden",
                            minHeight: "25px",
                        }}
                    >
                        {isUploading && (
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${uploadProgress}%` }}
                                aria-valuenow={uploadProgress}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            >
                                {uploadProgress}%
                            </div>
                        )}
                    </div>
                    {closeModal && (
                        <Button
                            color="secondary"
                            onClick={closeModal}
                            style={{ marginTop: "10px", marginRight: "10px" }}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        color="primary"
                        type="submit"
                        style={{ marginTop: "10px", marginLeft: "10px" }}
                        disabled={isUploading}
                    >
                        Upload
                    </Button>
                </Form>
            </TabPanel>
            <TabPanel>
                <Form onSubmit={handleTextSubmit}>
                    <FormGroup>
                        <Label for="resumeText">
                            <p>
                                Copy and paste a resume into the box below and
                                submit for evaluation. Candidate evaluations
                                typically take 2 minutes per resume. The
                                completed evaluation will appear in the list
                                after a browser refresh.
                            </p>
                        </Label>
                        <Input
                            type="textarea"
                            name="resumeText"
                            id="resumeText"
                            value={resumeText}
                            rows="8"
                            onChange={(e) => setResumeText(e.target.value)}
                            placeholder="Copy and paste resume text here..."
                        />
                    </FormGroup>
                    {closeModal && (
                        <Button
                            color="secondary"
                            onClick={closeModal}
                            style={{ marginTop: "10px", marginRight: "10px" }}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        color="primary"
                        type="submit"
                        style={{ marginTop: "10px", marginLeft: "10px" }}
                        disabled={isUploading}
                    >
                        Submit
                    </Button>
                </Form>
            </TabPanel>
            {showError && (
                <div className="error-confirmation">
                    <h2>Upload Error</h2>
                    <p>{errorMessage}</p>
                    <Button type="button" color="secondary" onClick={() => setShowError(false)}>Dismiss</Button>
                </div>
            )}
        </Tabs>
    );
};

export default ResumeUploadDialog;
