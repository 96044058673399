import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { getCookie } from '../../utils/SecurityUtils';
import axios from 'axios';
import '../RegisterForm/RegisterForm.css'; // Reuse existing styles
import './LogoUpload.css';

const LogoUpload = ({ type = 'logo' }) => {
    const [file, setFile] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [reload, setReload] = useState(false); // New state variable to trigger reload

    useEffect(() => {
        const fetchCurrentImage = async () => {
            const token = getCookie('AuthorizationToken');
            try {
                const response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/user/${type}`,
                    {
                        headers: {
                            Authorization: token,
                        },
                        responseType: 'blob',
                        validateStatus: status => status < 500 // Resolve only if the status code is less than 500
                    }
                );

                if (response.status === 200) {
                    // Create a local URL of the image blob
                    const imageUrl = URL.createObjectURL(response.data);
                    setCurrentImage(imageUrl);
                    setStatusMessage('');
                } else if (response.status === 404) {
                    setCurrentImage(null);
                    setStatusMessage(`A custom ${type} has not been set.`);
                } else {
                    const errorData = await response.data.text();
                    const errorMessage = JSON.parse(errorData).message;
                    setCurrentImage(null);
                    setStatusMessage(errorMessage || `An error occured reading the current ${type}.`);
                }
            } catch (error) {
                console.error(`Error fetching ${type}:`, error);
                setCurrentImage(null);
                setStatusMessage(`An error occured reading the current ${type}.`);
            }
        };

        fetchCurrentImage();
    }, [type, reload]); // Add reload to dependency array

    const validateFile = () => {
        const newErrors = [];
        if (!file) {
            newErrors.push({ message: "Please select a file to upload.", boldFields: [] });
            return newErrors;
        }

        console.log('Validating file type:', file.type);

        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/tiff'];
        if (!allowedTypes.includes(file.type)) {
            newErrors.push({ message: "Unsupported file type. Please upload a PNG, JPG, JPEG, GIF, or TIFF image.", boldFields: [] });
        }

        return newErrors;
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateFile();
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            setShowErrorModal(true);
            return;
        }

        const token = getCookie('AuthorizationToken');
        const formData = new FormData();
        formData.append(type, file);

        try {
            const response = await axios.put(
                `https://api.foveaai.com/evaluate/web/user/${type}`,
                formData,
                {
                    headers: {
                        Authorization: token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 200) {
                setSuccessMessage(response.data.message || `${capitalizeFirstLetter(type)} successfully updated.`);
                setFile(null);
                setReload(prev => !prev); // Trigger reload
            } else {
                setErrors([{ message: response.data.message || `Failed to update ${type}.`, boldFields: [] }]);
                setShowErrorModal(true);
            }
        } catch (error) {
            setErrors([{ message: error.response?.data?.message || "An unexpected error occurred.", boldFields: [] }]);
            setShowErrorModal(true);
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const getInstructions = (type) => {
        switch (type) {
            case 'logo':
                return 'Logos images are suggested to be 300 pixels wide by 300 pixels high.';
            case 'header':
            case 'footer':
                return 'Headers and footer images are suggested to be 2550 pixels wide and less than 450 pixels high.';
            default:
                return 'Images must be in png, jpg, jpeg, gif, or tiff format.';
        }
    };

    return (
        <div className="register-form-container">
            {successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}
            <Form className="register-form" onSubmit={handleSubmit}>
                {currentImage ? (
                    <div className="current-image-container">
                        <img src={currentImage} alt={`Current ${type}`} style={{ maxWidth: '100%', marginBottom: '20px' }} />
                    </div>
                ) : (
                    <div className="status-message">
                        <p>{statusMessage}</p>
                    </div>
                )}

                <Form.Group className="form-group">
                    <Form.Control
                        type="file"
                        id={`${type}-upload`}
                        accept=".png, .jpg, .jpeg, .gif, .tiff"
                        onChange={handleFileChange}
                    />
                </Form.Group>

                <Button type="submit" className="btn btn-primary" disabled={!file}>
                    Upload New {capitalizeFirstLetter(type)}
                </Button>

                <div className="instructions">
                    <p>{getInstructions(type)}</p>
                </div>
            </Form>

            {showErrorModal && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <h3>Oops! Please check the following:</h3>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>
                                    {error.message.split(" ").map((word, i) => (
                                        error.boldFields.includes(word.replace(/[^a-zA-Z]/g, "")) ? <b key={i}>{word} </b> : `${word} `
                                    ))}
                                </li>
                            ))}
                        </ul>
                        <Button onClick={() => setShowErrorModal(false)} className="btn btn-danger">Close</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LogoUpload;