import React from 'react';
import { useParams } from 'react-router-dom';
import './ResetPasswordPage.css';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
import FoveaAILogo from "../../assets/images/foveaai_logo_dark_text.svg";

const ResetPasswordPage = () => {
    const { token } = useParams();

    return (
        <div className="ResetPasswordPage">
            <div className="LogoContainer">
                <img className="FoveaAILogo" src={FoveaAILogo} alt="FoveaAI" />
            </div>
            <div className="Slogan">
                Identify top candidates in minutes.
            </div>
            <div className="ResetArea">
                <h1>Set a New Password</h1>
                <p className="ResetDescription">Please enter your new password below.</p>
            </div>
            <ResetPasswordForm token={token} />
        </div>
    );
}

export default ResetPasswordPage;