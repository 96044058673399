import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { getSubscriptions } from '../../utils/SecurityUtils';
import TagManager from 'react-gtm-module';
import './SubscriptionForm.css';

const stripePromise = loadStripe('pk_live_51Ps3HUP1JchvMqbVPd5V6Vmnkl9alRgarRCvuZCIUKRgJ26HaUe670tCHa5CbxiULo2yc1W17zCjJsuovoXw5EqL00eKkPlavQ');

const SubscriptionForm = () => {
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [currentSubscriptions, setCurrentSubscriptions] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [cookies] = useCookies(['AuthorizationToken']);
    const [refresh, setRefresh] = useState(0); // State variable to trigger refresh

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const response = await axios.get('https://api.foveaai.com/evaluate/web/service_plans');
                const sortedPlans = response.data.service_plans.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
                setPlans(sortedPlans);
            } catch (error) {
                console.error('Error fetching service plans:', error);
            }
        };

        const fetchCurrentSubscriptions = async () => {
            const subscriptions = await getSubscriptions();
            setCurrentSubscriptions(subscriptions);
        };

        fetchPlans();
        fetchCurrentSubscriptions();
    }, [refresh]); // Depend on refresh state

    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
    };

    const handleClearSelection = () => {
        setSelectedPlan(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedPlan) {
            alert('Please select a subscription plan.');
            return;
        }
        setShowConfirmModal(true);
    };

    const handleConfirmChange = async () => {
        setShowConfirmModal(false);
        const stripe = await stripePromise;
        try {
            const response = await axios.post('https://api.foveaai.com/evaluate/web/checkout', {
                planId: selectedPlan.psp_value,
                baseClientUrl: window.location.origin,
            }, {
                headers: { Authorization: cookies.AuthorizationToken },
            });

            const { sessionId = null, status, type, message = '' } = response.data;

            if (status === 'redirect_to_stripe' && type === 'success') {
                // Fire GTM event for initiating Stripe checkout
                try {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: 'initiatedCheckout',
                            planId: selectedPlan.psp_value,
                        },
                    });
                } catch (error) {
                    console.error("Error sending data layer event:", error);
                }

                const result = await stripe.redirectToCheckout({ sessionId });
                if (result.error) {
                    console.error('Error redirecting to Stripe Checkout:', result.error.message);
                }
            } else if (status !== 'redirect_to_stripe' && type === 'success') {
                setModalMessage(message);
                setShowSuccessModal(true);
                setRefresh(prev => prev + 1); // Trigger refresh
            } else if (type === 'error') {
                setModalMessage(message);
                setShowErrorModal(true);
            }
        } catch (error) {
            // Check if the error response has a message
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
            setModalMessage(errorMessage);
            setShowErrorModal(true);
            console.error('Error creating Stripe Checkout session:', error);
        }
    };

    const isUpgradeOrDowngrade = currentSubscriptions.length > 0;
    const currentPlanIds = currentSubscriptions.map(sub => sub.service_plan_id);

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        setRefresh(prev => prev + 1); // Trigger refresh
    };

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
        setRefresh(prev => prev + 1); // Trigger refresh
    };

    return (
        <div className="subscription-form-container">
            <Form onSubmit={handleSubmit}>
                <div className="subscription-plans-container">
                    {plans.map((plan) => {
                        const isCurrentPlan = currentPlanIds.includes(plan.id);
                        return (
                            <Card
                                key={plan.id}
                                className={`subscription-plan ${selectedPlan?.id === plan.id ? 'selected' : ''} ${isCurrentPlan ? 'current-plan' : ''}`}
                                onClick={() => !isCurrentPlan && handlePlanSelect(plan)}
                                style={{ borderColor: isCurrentPlan ? 'rgb(10, 223, 10)' : '' }}
                            >
                                <Card.Body className="card-body">
                                    <div>
                                        <Card.Title>{plan.name}</Card.Title>
                                        <Card.Text className="card-description">{plan.description}</Card.Text>
                                        <Card.Text><strong>Candidate Evaluations:</strong> {plan.unlimited_eval_credits ? 'Unlimited' : plan.eval_credits}</Card.Text>
                                        <Card.Text><strong>Additional Users:</strong> {plan.max_team_users}</Card.Text>
                                    </div>
                                    <Card.Text className="card-price">${plan.price} / {plan.billing_interval}</Card.Text>
                                </Card.Body>
                            </Card>
                        );
                    })}
                </div>
                <div className="button-group">
                    <Button type="button" className="btn btn-secondary" onClick={handleClearSelection} disabled={!selectedPlan}>
                        Clear Selection
                    </Button>
                    <Button type="submit" className="btn btn-primary" disabled={!selectedPlan}>
                        {isUpgradeOrDowngrade ? 'Change Plan' : 'Subscribe'}
                    </Button>
                </div>
            </Form>
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Plan Change</Modal.Title>
                </Modal.Header>
                <Modal.Body className="confirmation-modal-content">
                    <p>Are you sure you want to change your subscription plan?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmChange}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
                <Modal.Header closeButton className="modal-header-success">
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body className="confirmation-modal-content">
                    <p>{modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseSuccessModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
                <Modal.Header closeButton className="modal-header-error">
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className="confirmation-modal-content">
                    <p>{modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SubscriptionForm;