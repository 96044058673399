import "./HeaderMenu.css";
import FoveaAILogo from "../../assets/images/foveaai_logo_white_text.svg";
import GearIcon from "../../assets/images/gear-light.svg";
import HouseIcon from "../../assets/images/house-light.svg";
import LogoutIcon from "../../assets/images/right-from-bracket-light.svg"

import React from 'react';
import { Link } from "react-router-dom";
import { logout } from "../../utils/SecurityUtils";

const Header = () => {
    return (
        <div className="header">
            <div className="logo">
                <Link to="/dashboard">
                    <img src={FoveaAILogo} alt="FoveaAI" />
                </Link>
            </div>
            <div className="NavigationItems">
                <div className="get-extension">
                    <a href="https://chromewebstore.google.com/detail/foveaai-recruiter/cnolphggadgkhdlaafohnnpibdnciiik" target="_blank" rel="noopener noreferrer">
                        Get Extension
                    </a>
                </div>
                <div className="home" title="Open Jobs">
                    <Link to="/dashboard">
                        <img src={HouseIcon} alt="Home" />
                    </Link>
                </div>
                <div className="settings" title="Settings">
                    <Link to="/settings">
                        <img src={GearIcon} alt="Settings" />
                    </Link>
                </div>
                <div className="logout" title="Log Out" onClick={logout}>
                    <img src={LogoutIcon} alt="Log Out" style={{cursor: 'pointer'}} />
                </div>
            </div>
        </div>
    );
};

export default Header;
