import React, { useEffect, useState } from 'react';
import SubscriptionForm from '../../components/SubscriptionForm/SubscriptionForm';
import FoveaAILogo from "../../assets/images/foveaai_logo_dark_text.svg";
import './SubscribePage.css';
import { checkSubscription } from '../../utils/SecurityUtils';

const SubscribePage = () => {
    const [hasSubscription, setHasSubscription] = useState(false);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            const subscriptionExists = await checkSubscription();
            setHasSubscription(subscriptionExists);
        };

        fetchSubscriptionStatus();
    }, []);

    return (
        <div className="SubscribePage">
            <div className="LogoContainer">
                <img className="FoveaAILogo" src={FoveaAILogo} alt="FoveaAI" />
            </div>
            <h1>{hasSubscription ? 'Change Your Plan' : 'Subscribe to FoveaAI!'}</h1>
            <p>{hasSubscription ? 'Select a new plan or cancel your subscription.' : 'Choose a plan that fits your needs.'}</p>
            <SubscriptionForm />
        </div>
    );
};

export default SubscribePage;
