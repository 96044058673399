import "./UsageTable.css";

import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Pagination, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import { useCookies } from "react-cookie";

const UsageTable = () => {
    const [cookies] = useCookies(["AuthorizationToken"]);
    const [usage, setUsage] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [fromDate, setFromDate] = useState(addDays(new Date(), -30));
    const [toDate, setToDate] = useState(new Date());

    useEffect(() => {
        const fetchUsage = async () => {
            setIsLoading(true);

            // Convert the UTC dates to the format required by the API
            const formattedFromDate = format(fromDate, "yyyy-MM-dd");
            const formattedToDate = format(toDate, "yyyy-MM-dd");

            let usage_array = [];
            try {
                const response = await axios.get(
                    `https://api.foveaai.com/evaluate/web/usage?from_date=${formattedFromDate}&to_date=${formattedToDate}&page=${page}&per_page=${perPage}`,
                    {
                        headers: {
                            Authorization: cookies.AuthorizationToken,
                        },
                    }
                );

                console.log("Usage data response:", response);

                const response_data =
                    typeof response.data === "string"
                        ? JSON.parse(response.data)
                        : response.data;

                const log_entries =
                    typeof response_data.log_entries === "string"
                        ? JSON.parse(response_data.log_entries)
                        : response_data.log_entries;

                usage_array = Array.isArray(log_entries)
                    ? log_entries
                    : [log_entries];

                console.log("Usage data response data:", response_data);

                const fetchedTotalPages = response_data.total_pages;

                if (fetchedTotalPages > 0 && fetchedTotalPages >= page) {
                    setTotalPages(fetchedTotalPages);
                } else if (fetchedTotalPages > 0 && fetchedTotalPages < page) {
                    setTotalPages(fetchedTotalPages);
                    setPage(1);
                } else if (fetchedTotalPages === 0) {
                    setTotalPages(fetchedTotalPages);
                    setUsage([]);
                }

                console.log("Usage data found:", usage_array);
            } catch (error) {
                console.error("Error fetching usage data:", error);
            } finally {
                setIsLoading(false);
            }

            setUsage(usage_array);
        };

        fetchUsage();
    }, [fromDate, toDate, page, perPage, cookies.AuthorizationToken]);

    const renderPagination = () => {
        if (totalPages !== 0) {
            let items = [];
            const startPage = Math.max(1, page - 2);
            const endPage = Math.min(totalPages, page + 2);

            // Always add the first page and previous icon
            items.push(
                <Pagination.First
                    key="first"
                    onClick={() => setPage(1)}
                    disabled={page === 1}
                />,
                <Pagination.Prev
                    key="prev"
                    onClick={() => setPage(Math.max(1, page - 1))}
                    disabled={page === 1}
                />
            );

            // Generate page numbers, showing up to 2 pages before and after the current page
            for (let i = startPage; i <= endPage; i++) {
                items.push(
                    <Pagination.Item
                        key={i}
                        active={i === page}
                        onClick={() => setPage(i)}
                    >
                        {i}
                    </Pagination.Item>
                );
            }

            // Always add the next icon and last page
            items.push(
                <Pagination.Next
                    key="next"
                    onClick={() => setPage(Math.min(totalPages, page + 1))}
                    disabled={page === totalPages}
                />,
                <Pagination.Last
                    key="last"
                    onClick={() => setPage(totalPages)}
                    disabled={page === totalPages}
                />
            );

            return <Pagination>{items}</Pagination>;
        }
    };

    return (
        <div className="usage-table-container">
            {isLoading && <LoadingOverlay />}
            <div className="table-search-container">
                <Form>
                    <Row>
                        <Col>
                            <Form.Group
                                controlId="fromDateSelect"
                                className="form-entry"
                            >
                                <Form.Label>From Date:</Form.Label>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    maxDate={new Date()}
                                    className="form-control"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                controlId="toDateSelect"
                                className="form-entry"
                            >
                                <Form.Label>To Date:</Form.Label>
                                <DatePicker
                                    selected={toDate}
                                    onChange={(date) => {
                                        const nextDay = new Date(date);
                                        nextDay.setDate(nextDay.getDate() + 1);
                                        setToDate(nextDay);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    maxDate={new Date()}
                                    className="form-control"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                controlId="perPageSelect"
                                className="form-entry"
                            >
                                <Form.Label>Entries per page:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={perPage}
                                    onChange={(e) =>
                                        setPerPage(Number(e.target.value))
                                    }
                                >
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Candidate</th>
                            <th>Process</th>
                            <th>Result</th>
                            <th>Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalPages !== 0 ? (
                            usage.map((usageItem) => (
                                <tr key={usageItem.id}>
                                    <td>
                                        {new Date(
                                            new Date(
                                                usageItem.response_datetime
                                            ).getTime() -
                                                new Date().getTimezoneOffset() *
                                                    60000
                                        ).toLocaleString()}
                                    </td>
                                    <td>
                                        {usageItem.candidate_first_name}{" "}
                                        {usageItem.candidate_last_name}
                                    </td>
                                    <td>{usageItem.process}</td>
                                    <td>
                                        {usageItem.success ? (
                                            <span style={{ color: "green" }}>
                                                Success
                                            </span>
                                        ) : (
                                            <span style={{ color: "red" }}>
                                                Failed
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        {usageItem.error_message
                                            ? usageItem.error_message
                                            : ""}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No usage data found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {totalPages > 1 && (
                <div className="pagination-wrapper">{renderPagination()}</div>
            )}
        </div>
    );
};

export default UsageTable;
