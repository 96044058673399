import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './RequestPasswordResetForm.css';

const RequestPasswordResetForm = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.foveaai.com/evaluate/web/user/request_password_reset', { email });
            if (response.status === 200) {
                setSuccessMessage('A password reset link has been sent to your email address. Please check your inbox.');
                setEmail('');
            } else {
                setErrors([{ message: response.data.message || 'Failed to request password reset.', boldFields: [] }]);
                setShowErrorModal(true);
            }
        } catch (error) {
            setErrors([{ message: error.response?.data?.message || 'An unexpected error occurred.', boldFields: [] }]);
            setShowErrorModal(true);
        }
    };

    return (
        <div className="request-password-reset-form-container">
            {successMessage ? (
                <div className="success-message">
                    <p>{successMessage}</p>
                </div>
            ) : (
                <Form className="request-password-reset-form" onSubmit={handleSubmit}>
                    <Form.Group className="form-group">
                        <Form.Label htmlFor="email">Email Address</Form.Label>
                        <Form.Control
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Enter your email"
                        />
                    </Form.Group>
                    <Button type="submit" className="btn btn-primary">Request Password Reset</Button>
                </Form>
            )}
            {showErrorModal && (
                <div className="error-modal">
                    <div className="error-modal-content">
                        <h3>Oops! Please check the following:</h3>
                        <ul>
                            {errors.map((error, index) => (
                                <li key={index}>
                                    {error.message.split(" ").map((word, i) => (
                                        error.boldFields.includes(word.replace(/[^a-zA-Z]/g, "")) ? <b key={i}>{word} </b> : `${word} `
                                    ))}
                                </li>
                            ))}
                        </ul>
                        <Button onClick={() => setShowErrorModal(false)} className="btn btn-danger">Close</Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RequestPasswordResetForm;