import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import './CreditsAvailableView.css';

const CreditsAvailableView = () => {
    const [credits, setCredits] = useState('');
    const [unlimited, setUnlimited] = useState(false);
    const [cookies] = useCookies(['AuthorizationToken']);

    useEffect(() => {
        const fetchCredits = async () => {
            try {
                const response = await axios.get('https://api.foveaai.com/evaluate/web/user/service_plans', {
                    headers: {
                        Authorization: cookies.AuthorizationToken,
                    },
                });

                let responseData = response.data;

                // Check if the response data is a string and parse it
                if (typeof responseData === 'string') {
                    try {
                        responseData = JSON.parse(responseData);
                    } catch (parseError) {
                        console.error('Error parsing response data:', parseError);
                        return;
                    }
                }

                if (responseData && typeof responseData === 'object') {
                    const evalCreditsRemaining = responseData['eval_credits_remaining'];
                    const unlimitedEvalCredits = responseData['unlimited_eval_credits'];

                    setCredits(String(evalCreditsRemaining));
                    setUnlimited(unlimitedEvalCredits);
                } else {
                    console.error('Invalid response data format:', responseData);
                }
            } catch (error) {
                console.error('Error fetching credits:', error);
            }
        };

        fetchCredits();
    }, [cookies.AuthorizationToken]);

    return (
        <div className="credits-available-view">
            <b>Credits Remaining:</b> {unlimited ? 'Unlimited' : credits !== null ? credits : 'Loading...'}
        </div>
    );
};

export default CreditsAvailableView;
