// RegisterPage.js

import React from 'react';
import './RegisterPage.css';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import FoveaAILogo from "../../assets/images/foveaai_logo_dark_text.svg";

const RegisterPage = () => {
    return (
        <div className="RegisterPage">
            <div className="LogoContainer">
                <img className="FoveaAILogo" src={FoveaAILogo} alt="FoveaAI" />
            </div>
            <div className="Slogan">
                Identify top candidates in minutes.
            </div>
            <div className="RegisterArea">
                <h1>Let's get started!</h1>
                <p className="RegisterDescription">Please enter your email address, company name and a password to create your FoveaAI account.</p>
                <RegisterForm />
            </div>
        </div>
    );
}

export default RegisterPage;