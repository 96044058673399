import "./SettingsPage.css";

import UsageTable from "../../components/UsageTable/UsageTable";
import UserProfileTab from "../../components/UserProfileTab/UserProfileTab";
import CompanyProfileTab from "../../components/CompanyProfileTab/CompanyProfileTab";
import LogoManagementTab from "../../components/LogoManagementTab/LogoManagementTab";
import BillingTab from "../../components/BillingTab/BillingTab";
import TeamUsersTab from "../../components/TeamUsersTab/TeamUsersTab";
import CreditsAvailableView from "../../components/CreditsAvailableView/CreditsAvailableView";

import React, { useState, useEffect } from "react";
import { Tabs, Tab, Modal, Button } from "react-bootstrap";
import { getUserRoles } from "../../utils/SecurityUtils";
import { useCookies } from 'react-cookie';
import axios from 'axios';
import TagManager from 'react-gtm-module';

function SettingsPage() {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPrimary, setIsPrimary] = useState(false);
    const [activeTab, setActiveTab] = useState('user');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [cookies] = useCookies(['AuthorizationToken']);

    useEffect(() => {
        const fetchRoles = async () => {
            const userRoles = await getUserRoles();
            setIsAdmin(userRoles.some(role => role.name === 'admin'));
            setIsPrimary(userRoles.some(role => role.name === 'primary'));
        };

        fetchRoles();
    }, []);

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash.replace('#', '');
            if (hash) {
                setActiveTab(hash);
            }
        };

        window.addEventListener('hashchange', handleHashChange);
        handleHashChange(); // Call it initially to set the tab based on the current hash

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');

        if (sessionId) {
            const validateCheckoutSession = async () => {
                try {
                    const response = await axios.post('https://api.foveaai.com/evaluate/web/validate_checkout', {
                        sessionId
                    }, {
                        headers: { Authorization: cookies.AuthorizationToken }
                    });

                    const { message, type } = response.data;
                    setModalMessage(message);
                    if (type === 'success') {
                        setShowSuccessModal(true);

                        // Fire GTM event for successful subscription
                        try {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'subscribed',
                                    message: message,
                                    type: type,
                                }
                            });
                        } catch (error) {
                            console.error("Error sending data layer event:", error);
                        }
                    } else {
                        setShowErrorModal(true);
                    }
                } catch (error) {
                    const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
                    setModalMessage(errorMessage);
                    setShowErrorModal(true);
                }
            };

            validateCheckoutSession();
        }
    }, [cookies.AuthorizationToken]);

    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleCloseErrorModal = () => setShowErrorModal(false);

    return (
        <div className="settingsContainer">
            <div className="settingsHeaderContainer">
                <h1 className="settingsHeader">Settings</h1>
                <CreditsAvailableView />
            </div>
            <div className="settingsTabs">
                <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => {
                        setActiveTab(k);
                        window.location.hash = k; // Update the hash in the URL
                    }}
                    id="settings-tabs"
                    className="settingsTabs"
                >
                    <Tab eventKey="user" title="User Profile" className="settingsTab">
                        <UserProfileTab />
                    </Tab>
                    {(isAdmin || isPrimary) && (
                        <Tab eventKey="company" title="Company Profile" className="settingsTab">
                            <CompanyProfileTab />
                        </Tab>
                    )}
                    <Tab eventKey="logo" title="Custom Logos" className="settingsTab">
                        <LogoManagementTab />
                    </Tab>
                    {(isAdmin || isPrimary) && (
                        <Tab eventKey="teamUsers" title="TeamUsers" className="settingsTab">
                            <TeamUsersTab />
                        </Tab>
                    )}
                    {(isAdmin || isPrimary) && (
                        <Tab eventKey="billing" title="Subscription" className="settingsTab">
                            <BillingTab />
                        </Tab>
                    )}
                    <Tab eventKey="usage" title="Usage Log" className="settingsTab">
                        <div className="usageTableContainer">
                            <UsageTable />
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
                <Modal.Header closeButton className="modal-header-success">
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body className="confirmation-modal-content">
                    <p>{modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseSuccessModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showErrorModal} onHide={handleCloseErrorModal} centered>
                <Modal.Header closeButton className="modal-header-error">
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body className="confirmation-modal-content">
                    <p>{modalMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SettingsPage;
