import "./App.css";
import Header from "./components/Header/HeaderMenu";
import Footer from "./components/Footer/Footer";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import VerifyPage from "./pages/VerifyPage/VerifyPage";
import RestrictedPage from "./pages/RestrictedPage/RestrictedPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import JobPage from "./pages/JobPage/JobPage";
import AnalysisPage from "./pages/AnalysisPage/AnalysisPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import EulaPage from "./pages/EulaPage/EulaPage";
import SubscribePage from "./pages/SubscribePage/SubscribePage";
import ScrollToTop from "./utils/ScrollToTop";
import RequestPasswordResetPage from "./pages/RequestPasswordResetPage/RequestPasswordResetPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import { ProtectedRoute } from "./utils/SecurityUtils";

import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import TagManager from 'react-gtm-module';
const tagManagerArgs = {
    gtmId: 'GTM-KQXTF92M'
};
TagManager.initialize(tagManagerArgs);

function App() {
    return (
        <Router>
            <ScrollToTop />
            <div className="App content-container">
                <Header />
                <Routes>
                    <Route 
                        path="/login"
                        element={
                            <LoginPage />
                        }
                    />
                    <Route
                        path="/signup"
                        element={
                            <RegisterPage />
                        }
                    />
                    <Route
                        path="/verify/:token"
                        element={
                            <VerifyPage />
                        }
                    />
                    <Route
                        path="/restricted"
                        element={
                            <RestrictedPage />
                        }
                    />
                    <Route
                        path="/subscribe"
                        element={
                            <ProtectedRoute requireEula={true} requireSubscription={false} restrictedToRoles={['admin', 'primary']}>
                                <SubscribePage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute requireEula={true} requireSubscription={true}>
                                <DashboardPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/job/:jobId"
                        element={
                            <ProtectedRoute requireEula={true} requireSubscription={true}>
                                <JobPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/analysis/:analysisId"
                        element={
                            <ProtectedRoute requireEula={true} requireSubscription={true}>
                                <AnalysisPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/settings"
                        element={
                            <ProtectedRoute requireEula={true} requireSubscription={false}>
                                <SettingsPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/eula"
                        element={
                            <ProtectedRoute requireEula={true} requireSubscription={false}>
                                <EulaPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/request-password-reset"
                        element={<RequestPasswordResetPage />}
                    />
                    <Route
                        path="/reset-password/:token"
                        element={<ResetPasswordPage />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/login" replace />}
                    />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
