import React, { useState, useEffect, useCallback } from 'react';
import { Button, Table, Modal, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { getUserRoles } from '../../utils/SecurityUtils';
import './TeamUsersTab.css';

const TeamUsersTab = () => {
    const [users, setUsers] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newUser, setNewUser] = useState({ first_name: '', last_name: '', email: '' });
    const [cookies] = useCookies(['AuthorizationToken']);
    const [isAdmin, setIsAdmin] = useState(false);
    const [addUserError, setAddUserError] = useState(null);
    const [editUserError, setEditUserError] = useState(null);

    const fetchUsers = useCallback(async () => {
        try {
            const response = await axios.get('https://api.foveaai.com/evaluate/web/team/users', {
                headers: { Authorization: cookies.AuthorizationToken }
            });
            if (response.status === 200) {
                setUsers(response.data.users);
            } else {
                setAddUserError(response.data.message || 'Failed to fetch team users.');
            }
        } catch (error) {
            setAddUserError(error.response?.data?.message || 'An unexpected error occurred.');
        }
    }, [cookies.AuthorizationToken]);

    useEffect(() => {
        const checkUserRole = async () => {
            const roles = await getUserRoles();
            const hasAdminOrPrimaryRole = roles.some(role => role.name === 'admin' || role.name === 'primary');
            setIsAdmin(hasAdminOrPrimaryRole);
        };

        checkUserRole();
        if (isAdmin) {
            fetchUsers();
        }
    }, [cookies.AuthorizationToken, isAdmin, fetchUsers]);

    const handleAddUser = async () => {
        try {
            const response = await axios.post('https://api.foveaai.com/evaluate/web/team/user/profile', newUser, {
                headers: { Authorization: cookies.AuthorizationToken }
            });
            if (response.status === 200) {
                setShowAddModal(false);
                setNewUser({ first_name: '', last_name: '', email: '' });
                setAddUserError(null);
                fetchUsers(); // Refresh the table after adding a user
            } else {
                setAddUserError(response.data.message || 'Failed to add team user.');
            }
        } catch (error) {
            setAddUserError(error.response?.data?.message || 'An unexpected error occurred.');
        }
    };

    const handleUpdateUser = async () => {
        try {
            const response = await axios.put(`https://api.foveaai.com/evaluate/web/user/profile`, {
                user_id: selectedUser.user_id,
                ...selectedUser
            }, {
                headers: { Authorization: cookies.AuthorizationToken }
            });
            if (response.status === 200) {
                setShowEditModal(false);
                setEditUserError(null);
                fetchUsers(); // Refresh the table after updating a user
            } else {
                setEditUserError(response.data.message || 'Failed to update team user.');
            }
        } catch (error) {
            setEditUserError(error.response?.data?.message || 'An unexpected error occurred.');
        }
    };

    if (!isAdmin) {
        return (
            <div className="register-form-container">
                <p>You do not have the necessary permissions to view or edit the team users.</p>
            </div>
        );
    }

    return (
        <div className="team-users-tab">
            <Button variant="primary" onClick={() => setShowAddModal(true)}>
                Add Team User
            </Button>

            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>User Name</th>
                        <th>Roles</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.user_id} onClick={() => {
                            setSelectedUser(user);
                            setShowEditModal(true);
                        }}>
                            <td>
                                <span className={`status-icon ${user.active ? 'active' : 'inactive'}`}></span>
                            </td>
                            <td>{user.first_name}</td>
                            <td>{user.last_name}</td>
                            <td>{user.email}</td>
                            <td>{user.roles && Array.isArray(user.roles) ? user.roles.map(role => role.name).join(', ') : ''}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Team User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {addUserError && <Alert variant="danger" onClose={() => setAddUserError(null)} dismissible>{addUserError}</Alert>}
                    <Form>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={newUser.first_name}
                                onChange={(e) => setNewUser({...newUser, first_name: e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={newUser.last_name}
                                onChange={(e) => setNewUser({...newUser, last_name: e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                value={newUser.email}
                                onChange={(e) => setNewUser({...newUser, email: e.target.value})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAddUser}>
                        Add User
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Team User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editUserError && <Alert variant="danger" onClose={() => setEditUserError(null)} dismissible>{editUserError}</Alert>}
                    <Form>
                        <Form.Group>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={selectedUser?.first_name || ''}
                                onChange={(e) => setSelectedUser({...selectedUser, first_name: e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={selectedUser?.last_name || ''}
                                onChange={(e) => setSelectedUser({...selectedUser, last_name: e.target.value})}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Active</Form.Label>
                            <Form.Check 
                                type="switch"
                                checked={selectedUser?.active || false}
                                onChange={(e) => setSelectedUser({...selectedUser, active: e.target.checked})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpdateUser}>
                        Update User
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default TeamUsersTab;